<template>
  <v-container fluid class="ma-0 pa-0 white">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.mdAndUp">
      <v-flex xs12 class="max-width-1400 full-width ma-auto px-2">
        <v-layout row wrap>
          <v-flex xs12 sm7 md8 :class="{'pa-3': $vuetify.breakpoint.mdAndUp, 'pr-2 pl-3 py-3': $vuetify.breakpoint.smOnly}">
            <v-card color="light-background" flat tile>
              <v-card-text>
                <h1
                  :class="{'font-35': $vuetify.breakpoint.mdAndUp, 'font-20': $vuetify.breakpoint.smAndDown}"
                  class="primary--text graphik-light mb-2 mt-0"
                >{{ $ml.get('checkout_title') }}</h1>

                <h2
                  :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                  class="graphik-light mb-4 dark-grey--text"
                >{{ $ml.get('checkout_description') }}</h2>

                <!-- Mobile -->
                <v-layout row wrap>
                  <v-expansion-panel v-if="product.product && $vuetify.breakpoint.xsOnly" class="hidden-shadow my-3" v-model="panel">
                    <v-expansion-panel-content class="light-background ">
                      <template v-slot:header>
                        <v-flex d-flex>
                          <h3 class="text-xs-left graphik-bold font-20 primary--text mb-0">{{ $ml.get('general_order_summary') }}</h3>
                          <v-spacer></v-spacer>
                          <v-icon color="primary">fas fa-chevron-down</v-icon>
                        </v-flex>
                      </template>

                      <v-card color="light-background" flat tile>
                        <v-card-text class="pa-0">
                          <v-img
                            v-if="company_logo"
                            max-width="250px"
                            contain
                            position="center center"
                            class="ma-auto"
                            :src="company_logo"
                            :lazy-src="company_logo"
                          />

                          <p class="graphik-bold font-17 dark-grey--text">{{ $ml.get('quoter_insurace') }} {{ $route.params.type }}</p>

                          <p class="graphik-medium uppercase font-17 dark-grey--text ma-0">{{ $ml.get('fields_coverage') }} {{ product.product.public_name }}</p>

                          <v-expansion-panel class="hidden-shadow" v-model="coverages_panel">
                            <v-expansion-panel-content class="light-background">
                              <template v-slot:header>
                                <p class="graphik-medium font-14 dark-grey--text ma-0">{{ $ml.get('general_view_details') }} <v-icon small>{{ coverages_panel === 0 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</v-icon></p>
                              </template>

                              <v-card flat height="100%" class="light-background">
                                <v-card-text class="pa-0">
                                  <p class="graphik-light font-14 dark-grey--text ma-0" v-for="(coverage, index) in product.insurance_information.coverage" :key="index">• {{ coverage.text }}</p>
                                </v-card-text>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>

                          <v-divider class="grey my-3"></v-divider>

                          <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_validity') }}</p>
                          <p class="graphik-light font-15 dark-grey--text">{{ product.quote.start_date | moment('LL') }} {{ $ml.get('general_to') }} {{ product.quote.end_date | moment('LL') }}</p>

                          <template v-if="$route.params.type === 'auto'">
                            <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_car_model') }}</p>
                            <p class="graphik-light font-15 dark-grey--text">{{ product.quote.car_model }}</p>

                            <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_car_brand') }}</p>
                            <p class="graphik-light font-15 dark-grey--text">{{ product.quote.car_brand }}</p>

                            <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_car_uuid') }}</p>
                            <p class="graphik-light font-15 dark-grey--text">{{ product.quote.car_version }}</p>
                          </template>

                          <template v-if="$route.params.type === 'hogar'">
                            <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_address') }}</p>
                            <p class="graphik-light font-15 dark-grey--text">{{ product.quote.street }} {{ product.quote.street_number }}, {{ product.quote.neighborhood }} {{ product.quote.zip_code }}. {{ product.quote.city }}, {{ product.quote.state }}.</p>
                          </template>

                          <v-divider class="grey my-3"></v-divider>

                          <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_coupon') }}</p>
                          <p class="graphik-light font-15 dark-grey--text">{{ product.quote.coupon.coupon_code }}</p>

                          <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_payment_method') }}</p>
                          <p class="graphik-light font-15 dark-grey--text">{{ product.quote.frequency_description }}</p>

                          <v-divider class="grey my-3"></v-divider>

                          <v-flex d-flex>
                            <p class="graphik-medium font-20 dark-grey--text ma-0">{{ $ml.get('fields_total') }}</p>
                            <v-spacer></v-spacer>
                            <p class="graphik-light font-20 dark-grey--text ma-0 text-xs-right">{{ product.insurance_information.total | price }}</p>
                          </v-flex>

                          <v-divider class="grey my-3"></v-divider>

                          <v-flex d-flex>
                            <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_first_payment') }}</p>
                            <v-spacer></v-spacer>
                            <p class="graphik-light font-15 dark-grey--text ma-0 text-xs-right">{{ product.insurance_information.first_payment | price }}</p>
                          </v-flex>

                          <v-flex d-flex v-if="product.insurance_information.next_payment">
                            <p class="graphik-medium font-15 dark-grey--text ma-0">{{ product.insurance_information.frequency - 1 }} {{ $ml.get('fields_next_payment') }}</p>
                            <v-spacer></v-spacer>
                            <p class="graphik-light font-15 dark-grey--text ma-0 text-xs-right">{{ product.insurance_information.next_payment | price }}</p>
                          </v-flex>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-layout>

                <!-- Data -->
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-form v-model="valid_checkout">
                      <v-container fluid pa-0 ma-0 grid-list-xl>
                        <v-layout row wrap>
                          <v-flex xs12>
                            <h3 
                              :class="{'font-20': $vuetify.breakpoint.mdAndUp, 'font-17': $vuetify.breakpoint.smAndDown}"
                              class="graphik-bold primary--text my-4"
                            >{{ $ml.get('checkout_payment') }}</h3>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_email') }}</p>

                            <v-text-field
                              v-model="payment.billing_email"
                              solo
                              clearable
                              required
                              :disabled="g_isLoading"
                              :rules="g_email_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_cellphone') }}</p>

                            <v-text-field
                              v-model="payment.billing_phone"
                              solo
                              clearable
                              required
                              mask="phone"
                              :disabled="g_isLoading"
                              :rules="g_phone_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_name') }}</p>

                            <v-text-field
                              v-model="payment.card_name"
                              solo
                              clearable
                              required
                              :disabled="g_isLoading"
                              :rules="g_required_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_last_names') }}</p>

                            <v-text-field
                              v-model="payment.card_last_name"
                              solo
                              clearable
                              required
                              :disabled="g_isLoading"
                              :rules="g_required_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_credit_card') }}</p>

                            <v-text-field
                              v-model="payment.card_number"
                              solo
                              clearable
                              mask="credit-card"
                              required
                              :disabled="g_isLoading"
                              :rules="g_credit_card_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm3>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_expiration') }}</p>

                            <v-text-field
                              v-model="payment.card_date_month"
                              solo
                              clearable
                              required
                              :disabled="g_isLoading"
                              placeholder="MM"
                              mask="##"
                              :rules="g_expiration_month_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm3>
                            <p class="graphik-medium font-17 mb-2 dark-grey--text placeholder">{{ $ml.get('fields_expiration') }}</p>

                            <v-text-field
                              v-model="payment.card_date_year"
                              solo
                              clearable
                              required
                              placeholder="YYYY"
                              mask="####"
                              :rules="expiration_year_rules"
                              :disabled="g_isLoading"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_cvc') }}</p>

                            <v-text-field
                              v-model="payment.card_vcc"
                              solo
                              clearable
                              mask="####"
                              required
                              :disabled="g_isLoading"
                              :rules="g_cvc_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex
                            xs12
                            sm6
                            v-if="
                              product.product && (
                                product.product.company_internal_name === 'gnp'
                                || product.product.company_internal_name === 'primeroSeguros'
                                || product.product.company_internal_name === 'ana'
                                || product.product.company_internal_name === 'afirme'
                                || product.product.company_internal_name === 'qualitas'
                              )
                            "
                          >
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_bank') }}</p>

                            <v-autocomplete
                              v-model="payment.card_bank"
                              :items="options.banks"
                              solo
                              clearable
                              :disabled="g_isLoading"
                              required
                              :rules="g_required_rules"
                              :no-data-text="$ml.get('general_no_results')"
                              class="hidden-shadow small-input"
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-divider class="grey my-3"></v-divider>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex xs12>
                            <h3 
                              :class="{'font-20': $vuetify.breakpoint.mdAndUp, 'font-17': $vuetify.breakpoint.smAndDown}"
                              class="graphik-bold primary--text my-4"
                            >{{ $ml.get('checkout_address') }}</h3>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_zip_code') }}</p>

                            <v-text-field
                              v-model="payment.billing_zip_code"
                              solo
                              clearable
                              required
                              :disabled="g_isLoading"
                              mask="#####"
                              :rules="g_zip_code_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_neighborhood') }}</p>

                            <v-autocomplete
                              v-model="payment.billing_location"
                              :items="options.neighborhoods"
                              solo
                              clearable
                              :disabled="g_isLoading || !payment.billing_zip_code"
                              required
                              :rules="g_required_rules"
                              :no-data-text="$ml.get('general_no_results')"
                              class="hidden-shadow small-input"
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_street_and_number') }}</p>

                            <v-text-field
                              v-model="payment.billing_street"
                              solo
                              clearable
                              required
                              :disabled="g_isLoading"
                              :rules="g_required_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_city') }}</p>

                            <v-text-field
                              v-model="payment.billing_city"
                              solo
                              disabled
                              required
                              :rules="g_required_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_state') }}</p>

                            <v-text-field
                              v-model="payment.billing_state"
                              solo
                              disabled
                              required
                              :rules="g_required_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-flex>
                </v-layout>

                <v-layout row wrap pt-3>
                  <v-flex xs12 text-xs-right>
                    <v-btn 
                      color="primary" 
                      depressed
                      :loading="g_isLoading"
                      class="pr-3 pl-4 normalcase graphik-bold-italic my-0 ml-3 border-radius-5 white--text"
                      :disabled="g_isLoading || !valid_checkout"
                      @click="checkout()"
                    >{{ $ml.get('general_pay') }}<v-icon dark class="ml-2">arrow_forward</v-icon></v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>

          <!-- Web & Tablet -->
          <v-flex xs12 sm5 md4 :class="{'pa-3': $vuetify.breakpoint.mdAndUp, 'pl-2 pr-3 py-3': $vuetify.breakpoint.smOnly}" v-if="product.product && $vuetify.breakpoint.smAndUp">
            <v-card color="light-background" flat tile>
              <v-card-text>
                <h3 class="text-xs-center graphik-bold font-20 primary--text mb-0">{{ $ml.get('general_order_summary') }}</h3>

                <v-img
                  v-if="company_logo"
                  max-width="250px"
                  contain
                  position="center center"
                  class="ma-auto"
                  :src="company_logo"
                  :lazy-src="company_logo"
                />

                <p class="graphik-bold font-17 dark-grey--text">{{ $ml.get('quoter_insurace') }} {{ $route.params.type }}</p>

                <p class="graphik-medium uppercase font-17 dark-grey--text ma-0">{{ $ml.get('fields_coverage') }} {{ product.product.public_name }}</p>

                <v-expansion-panel class="hidden-shadow" v-model="coverages_panel">
                  <v-expansion-panel-content class="light-background">
                    <template v-slot:header>
                      <p class="graphik-medium font-14 dark-grey--text ma-0">{{ $ml.get('general_view_details') }} <v-icon small>{{ coverages_panel === 0 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</v-icon></p>
                    </template>

                    <v-card flat height="100%" class="light-background">
                      <v-card-text class="pa-0">
                        <p class="graphik-light font-14 dark-grey--text ma-0" v-for="(coverage, index) in product.insurance_information.coverage" :key="index">• {{ coverage.text }}</p>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-divider class="grey my-3"></v-divider>

                <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_validity') }}</p>
                <p class="graphik-light font-15 dark-grey--text">{{ product.quote.start_date | moment('LL') }} {{ $ml.get('general_to') }} {{ product.quote.end_date | moment('LL') }}</p>

                <template v-if="$route.params.type === 'auto'">
                  <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_car_model') }}</p>
                  <p class="graphik-light font-15 dark-grey--text">{{ product.quote.car_model }}</p>

                  <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_car_brand') }}</p>
                  <p class="graphik-light font-15 dark-grey--text">{{ product.quote.car_brand }}</p>

                  <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_car_uuid') }}</p>
                  <p class="graphik-light font-15 dark-grey--text">{{ product.quote.car_version }}</p>
                </template>

                <template v-if="$route.params.type === 'hogar'">
                  <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_address') }}</p>
                  <p class="graphik-light font-15 dark-grey--text">{{ product.quote.street }} {{ product.quote.street_number }}, {{ product.quote.neighborhood }} {{ product.quote.zip_code }}. {{ product.quote.city }}, {{ product.quote.state }}.</p>
                </template>

                <v-divider class="grey my-3"></v-divider>

                <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_coupon') }}</p>
                <p class="graphik-light font-15 dark-grey--text">{{ product.quote.coupon.coupon_code }}</p>

                <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_payment_method') }}</p>
                <p class="graphik-light font-15 dark-grey--text">{{ product.quote.frequency_description }}</p>

                <v-divider class="grey my-3"></v-divider>

                <v-flex d-flex>
                  <p class="graphik-medium font-20 dark-grey--text ma-0">{{ $ml.get('fields_total') }}</p>
                  <v-spacer></v-spacer>
                  <p class="graphik-light font-20 dark-grey--text ma-0 text-xs-right">{{ product.insurance_information.total | price }}</p>
                </v-flex>

                <v-divider class="grey my-3"></v-divider>

                <v-flex d-flex>
                  <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_first_payment') }}</p>
                  <v-spacer></v-spacer>
                  <p class="graphik-light font-15 dark-grey--text ma-0 text-xs-right">{{ product.insurance_information.first_payment | price }}</p>
                </v-flex>

                <v-flex d-flex v-if="product.insurance_information.next_payment">
                  <p class="graphik-medium font-15 dark-grey--text ma-0">{{ product.insurance_information.frequency - 1 }} {{ $ml.get('fields_next_payment') }}</p>
                  <v-spacer></v-spacer>
                  <p class="graphik-light font-15 dark-grey--text ma-0 text-xs-right">{{ product.insurance_information.next_payment | price }}</p>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
function dynamicallyLoadScript(url) {
  var script = document.createElement("script");
  script.src = url;

  document.head.appendChild(script);
}

dynamicallyLoadScript('https://cdn.conekta.io/js/latest/conekta.js')

export default {
  name: 'Checkout',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_CHECKOUT,
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_CHECKOUT
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_CHECKOUT
      }
    ]
  },
  data () {
    return {
      panel: null,
      valid_checkout: false,
      coverages_panel: null,
      expiration_year_rules: [
        v => !!v || this.$ml.get('rules_required_field'),
        v => (!!v && window.Conekta.card.validateExpirationDate(this.payment.card_date_month, v)) || this.$ml.get('rules_invalid_date')
      ],
      payment: {
        card_name: '',
        card_last_name: '',
        card_bank: '',
        card_number: '',
        card_date_month: '',
        card_date_year: '',
        card_vcc: '',
        card_type: 0,
        billing_street: '',
        billing_location: '',
        billing_city: '',
        billing_state: '',
        billing_zip_code: ''
      },
      answer_uuids: {},
      product: {},
      options: {
        banks: [],
        neighborhoods: []
      }
    }
  },
  computed: {
    disabled () {
      return !!this.$route.params.policy
    },
    company_logo () {
      if (!this.product.product) {
        return null
      }

      if (this.$route.params.type === 'auto') {
        return require('@/assets/img/companies/' + this.product.product.company_internal_name + '-full.png')
      }

      if (this.$route.params.type === 'hogar') {
        return require('@/assets/img/companies/' + this.product.product.company_internal_name + 'hogar-' + this.product.product.product_package_index + '-full.png')
      }

      return null
    }
  },
  watch: {
    'payment.billing_zip_code' () {
      this.getNeighborhoods()
    }
  },
  methods: {
    // CRUD
    getNeighborhoods () {
      if (!this.payment.billing_zip_code || this.payment.billing_zip_code.length < 5) {
        this.neighborhoods = []
        return
      }
      this.g_isLoading = true
      this.$api.getNeighborhoods(
        this.payment.billing_zip_code,
        response => {
          this.options.neighborhoods = response.body.data

          if (this.options.neighborhoods.length) {
            this.payment.billing_city = response.body.data[0].city_name
            this.payment.billing_state = response.body.data[0].state
          }
          this.g_isLoading = false
        },
        response => this.$errorHandling(response).then(() => {
          this.getNeighborhoods()
        }, () => {})
      )
    },
    getPolicy () {
      this.g_isLoading = true
      this.$api.checkoutGetPolicy(
        this.$route.params.policy,
        response => {
          this.product = response.body.data
          this.payment.billing_phone = response.body.data.quote.cellphone
          this.payment.billing_email = response.body.data.quote.email
          if (
            this.product.product.company_internal_name === 'gnp'
            || this.product.product.company_internal_name === 'primeroSeguros'
            || this.product.product.company_internal_name === 'ana'
            || this.product.product.company_internal_name === 'qualitas'
            || this.product.product.company_internal_name === 'afirme'
          ) {
            this.getBanks()
          } else {
            this.g_isLoading = false
          }
        },
        response => this.$errorHandling(response).then(() => {
          this.getPolicy()
        }, () => {})
      )
    },
    getBanks () {
      this.g_isLoading = true
      this.$api.getBanks(
        this.product.product.company_internal_name,
        response => {
          this.options.banks = response.body.data
          this.g_isLoading = false
        },
        response => this.$errorHandling(response).then(() => {
          this.getBanks()
        }, () => {})
      )
    },
    checkout () {
      this.g_isLoading = true

      this.$api.payPolicy(
        this.$route.params.policy,
        this.payment,
        () => {
          this.$analytics('checkout_payment_success', { value: this.product.insurance_information.total })
          this.$router.push('/gracias/' + this.$route.params.policy)
          this.g_isLoading = false
        },
        response => this.$errorHandling(response).then(() => {
          this.checkout()
        }, () => {
          this.$analytics('checkout_payment_failure', { value: this.product.insurance_information.total })
        })
      )
    }
  },
  mounted () {
    this.getPolicy()
  }
}
</script>

<style scoped>
</style>
